const codeYM = 90827180

export default class YMStat {
  // клики по элементам меню
  static clickMenuItemYM(value) {
    const params = { 'Главная страница': { 'Клики по меню в шапке сайта': [`${value}`] } }
    window.ym(codeYM, 'params', { params: params })
  }
  // клики по элеменетам выбора языка
  static clickLangItemYM(value) {
    const params = { 'Главная страница': { 'Выбор языка сайта': [`${value}`] } }
    window.ym(codeYM, 'params', { params: params })
  }
  // клики по иконке поиска
  static clickSearchItemYM(value) {
    const params = { 'Главная страница': { 'Поиск по сайту': [`${value}`] } }
    window.ym(codeYM, 'params', { params: params })
  }
  // сабмит формы поиска
  static clickSearchItemResultYM(value) {
    const params = { 'Главная страница': { 'Поиск по сайту': [`${value}`] } }
    window.ym(codeYM, 'params', { params: params })
  }
  // клик по кнопке навигации слайдера
  static clickSliderNavYM(value) {
  const params = { 'Главная страница': { 'Блок Слайдер': [`${value}`] } }
    window.ym(codeYM, 'params', { params: params })
  }
  // клик по кнопке "Подробнее" на слайдере - как стянуть название?
  static clickSliderMoreYM(value) {
    const params = { "Главная страница":{"Блок Слайдер":"Клик Подробнее" , value} }
    window.ym(codeYM, 'params', { params: params })
  }
  // клик по  каждому отдельному контенту
  static clickContentYM(value) {
    const params = { 'Главная страница': { 'Блок Контент': [`${value}`] } }
    window.ym(codeYM, 'params', { params: params })
  }
  // клик на "все экскурсии"
  static clickExcursionsYM(value) {
    const params = { 'Главная страница': { 'Блок Экскурсии': [`${value}`] } }
    window.ym(codeYM, 'params', { params: params })
  }
  // клик на город
  static clickCityYM(value) {
    const params = { "Главная страница":{"Блок Экскурсии":"Выбранный город", value } }
    window.ym(codeYM, 'params', { params: params })
  }
  // клик на экскурсию
  static clickExcursionYM(value) {
    const params = { "Главная страница":{"Блок Экскурсии":"Выбранная экскурсия", value } }
    window.ym(codeYM, 'params', { params: params })
  }
  // клик на Как посетить?
  static clickHowToVisitYM(value) {
    const params = { 'Главная страница': { 'Блок Экскурсии': [`${value}`] } }
    window.ym(codeYM, 'params', { params: params })
  }
  // клик на смотреть
  static clickWatchYM(value) {
    const params = { "Главная страница":{"Блок Виртуальная выставка":"Клик смотреть", value } }
    window.ym(codeYM, 'params', { params: params })
  }
  // клик на Все события
  static clickAllEventsYM(value) {
    const params = { 'Главная страница': { 'Блок События': [`${value}`] } }
    window.ym(codeYM, 'params', { params: params })
  }
  // клик на событие
  static clickEventYM(value) {
    const params = { "Главная страница":{"Блок События":"Выбранное событие", value } }
    window.ym(codeYM, 'params', { params: params })
  }
  // клик на Все статьи
  static clickAllArticlesYM(value) {
    const params = { 'Главная страница': { 'Блок Статьи': [`${value}`] } }
    window.ym(codeYM, 'params', { params: params })
  }
  // выбор конкретной темы 
  static clickFiltrationYM(value) {
    const params = { "Главная страница":{"Блок Статьи":"Выбранная тема статьи", value } }
    window.ym(codeYM, 'params', { params: params })
  }
  // клик по конкретной  статье обрабатывается методом clickAllArticlesYM
  // ввод email и подписка
  static clickSubscribeInputYM(value) {
    const params = { 'Главная страница': { 'Футер': [`${value}`] } }
    window.ym(codeYM, 'params', { params: params })
  }
  // клик по элементам футера
  static clickFooterYM(value) {
    const params = { 'Главная страница': { 'Футер': [`${value}`] } }
    window.ym(codeYM, 'params', { params: params })
  }
}