import Vue from 'vue';
Vue.directive('metrik-click', {
  bind: function (el, binding, vnode) {
    el.clickMetrikEvent = function (event) {
      // here I check that click was outside the el and his children
      binding.value.bind(vnode.context)(event)
    };
    el.addEventListener('click', el.clickMetrikEvent);
  },
  unbind: function (el) {
    el.removeEventListener('click', el.clickMetrikEvent);
  },
});