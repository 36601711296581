import "./scss/app.scss";
import "swiper/swiper-bundle.css";

import Vue from 'vue';
import { Swiper as SwiperClass, Pagination, Mousewheel, Autoplay, Navigation, Grid, EffectFade, EffectCreative } from 'swiper/swiper.esm';
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter';
import VueScreenSize from 'vue-screen-size';
import HighchartsVue from 'highcharts-vue';
import { InlineSvgPlugin } from 'vue-inline-svg';
import Vuelidate from 'vuelidate';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueMask from 'v-mask';

Vue.config.productionTip = false
SwiperClass.use([Pagination, Mousewheel, Autoplay, Navigation, Grid, EffectFade, EffectCreative]);
Vue.use(getAwesomeSwiper(SwiperClass));
Vue.use(VueScreenSize);
Vue.use(HighchartsVue);
Vue.use(InlineSvgPlugin);
Vue.use(Vuelidate);
Vue.use(VueAxios, axios);
Vue.use(VueMask);

Vue.component('wrapper', {
  name: 'wrapper',
  render(createElement) {
    return createElement(this.tag, this.$attrs, this.$slots.default);
  },
  props: {
    tag: {
      type: String,
      required: true,
    },
  },
})

import './directives/outsideClick';
import './directives/outsideScroll';
import './directives/metrikClick';
import YMStat from "./tools/ymStat";


new Vue({
  components: {
    Slider: () => import('./components/Slider.vue'),
    CustomSelect: () => import('./components/CustomSelect.vue'),
    SliderMultiple: () => import('./components/SliderMultiple.vue'),
    SliderGallery: () => import('./components/SliderGallery.vue'),
    SliderText: () => import('./components/SliderText.vue'),
    Accordion: () => import('./components/Accordion.vue'),
    ContactsMap: () => import('./components/ContactsMap.vue'),
    ArticleHeader: () => import('./components/ArticleHeader.vue'),
    Chart: () => import('./components/Chart.vue'),
    ChartPie: () => import('./components/ChartPie.vue'),
    BeforeAfterSlider: () => import('./components/BeforeAfterSlider.vue'),
    Sharing: () => import('./components/Sharing.vue'),
    BranchesList: () => import('./components/BranchesList.vue'),
    Survey: () => import('./components/Survey.vue'),
    Story: () => import('./components/Story.vue'),
    MemoDate: () => import('./components/MemoDate.vue'),
    HHeader: () => import('./components/bank-home/Header'),
    HPhotos: () => import('./components/bank-home/Photos'),
    ChartPieTween: () => import('./components/ChartPieTween.vue'),
    ChartColumn: () => import('./components/ChartColumn.vue'),
    ChartColumnTween: () => import('./components/ChartColumnTween.vue'),
    ChartLogarithmic: () => import('./components/ChartLogarithmic.vue'),
    CitySelect: () => import('./components/CitySelect.vue'),
    RefBlock: () => import('./components/RefBlock.vue'),
    ImageMagnify: () => import('./components/ImageMagnify.vue'),
    ExcursionsList: () => import('./components/excursions/List'),
    ScrollUp: () => import('./components/ScrollUp'),
    SubscriptionForm: () => import('./components/SubscriptionForm.vue'),
    Library: () => import('./components/Library.vue'),
    Leaflet: () => import('./components/Leaflet.vue'),
    SliderAbout: () => import('./components/SliderAbout.vue'),
    EventsList: () => import('./components/EventsList.vue'),
    ReservationForm: () => import('./components/ReservationForm.vue'),
    ReservationAdditional: () => import('./components/ReservationAdditional.vue'),
    FeedbackForm: () => import('./components/FeedbackForm.vue'),
  },
  data() {
    return {
      showAdditionalMenu: false,
      showMobileMenu: false,
      sidePanelOpened: false,
      onTop: true,
      isNotMainScreen: false,
      searchString: this.getGetParam('q'),
      backUrl: this.getGetParam('from'),
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    getGetParam(param) {
      return decodeURIComponent((location.search.substr(1)
        .split('&')
        .map((el) => el.split('='))
        .filter((el) => el[0] === param)[0] ?? [])[1] ?? '').replaceAll('+', ' ');
    },
    closeMenu() {
      this.showAdditionalMenu = false;
    },
    handleScroll() {
      this.onTop = window.scrollY < 100;
      const windowHeight = window.innerHeight;
      this.isNotMainScreen = window.scrollY > windowHeight * 2;
    },
    sendStat(e, param, value){
      YMStat[param](value)
    },
    goFilter(data, filterName = 'tag', path = '') {
      const params = new URLSearchParams(location.search.substring(1));

      if (filterName === 'q' && typeof data === 'string') {
        data = data.replace(/[^A-Za-zА-Яа-я\s\d.,]/g, '');
      }

      if (data !== 'all')
        params.set(filterName, data);
      else
        params.delete(filterName);

      if (path.length === 0) {
        location.search = params.toString();
      } else {
        location.href = location.protocol + '//' + location.host + path + '?' + params.toString();
      }
    },
  }
}).$mount('#app')
