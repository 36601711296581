import Vue from 'vue';
Vue.directive('scroll-outside', {
  bind: function (el, binding, vnode) {
    const vm = vnode.context
    el.scrollOutsideEvent = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        vm[binding.expression](event);
      }
    };
    window.addEventListener('scroll', el.scrollOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('scroll', el.scrollOutsideEvent)
  },
});
